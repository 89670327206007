<template>
  <v-dialog
    v-model="show"
    max-width="1000px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="announcementId"
            class="body-2"
          >
            <i>#{{ announcement.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="subject"
              >
                <v-text-field
                  v-model="announcement.subject"
                  hide-details="auto"
                  label="Assunto"
                  filled
                  dense
                  :error-messages="errors"
                  :disabled="announcementId > 0"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row class="my-3 black--text">
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="text"
              >
                <quill-editor
                  v-model="announcement.text"
                  class="editor"
                  :options="editorOption"
                  :disabled="announcementId > 0"
                />
                <div
                  v-if="errors.length > 0"
                  class="v-messages theme--light error--text mt-2"
                >
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="typesOfContacts"
              >
                <v-select
                  v-model="announcement.typesOfContacts"
                  item-value="id"
                  item-text="description"
                  label="Tipos de Contatos"
                  hide-details="auto"
                  multiple
                  :items="typesOfContacts"
                  :error-messages="errors"
                  :disabled="announcementId > 0"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip 
                      v-if="index <= 2"
                      label
                      small
                    >
                      <span>{{ item.description }}</span>
                    </v-chip>
                    <span
                      v-if="index === 3"
                      class="grey--text text-caption"
                    >
                      (+{{ announcement.typesOfContacts.length - 3 }} outros)
                    </span>
                    
                  </template>
                </v-select>
              </validation-provider>
            </v-col>

            <v-col
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="replyTo"
              >
                <v-text-field
                  v-model="announcement.replyTo"
                  hide-details="auto"
                  label="Responder para"
                  :error-messages="errors"
                  :disabled="announcementId > 0"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="cities"
              >
                <v-autocomplete
                  v-model="announcement.cities"
                  item-value="id"
                  item-text="name"
                  label="Prefeituras"
                  hide-details="auto"
                  multiple
                  auto-select-first
                  append-outer-icon="mdi-map"
                  :items="cities"
                  :error-messages="errors"
                  :disabled="announcementId > 0"
                  :counter="cities.length"
                >
                  <template v-slot:append-outer>
                    <v-menu bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          class="mt-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            v-text="'mdi-account-multiple-check-outline'"
                          />
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          link
                          dense
                          @click="dialogSelectCitiesThatDidNotRespondToASurvey = true" 
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              Selecionar prefeituras que não responderam pesquisa
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggleSelectAll"
                    >
                      <v-list-item-action>
                        <v-icon :color="announcement.cities > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <b>Selecionar todas</b>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="association in associations"
                      ripple
                      :key="association.id"
                      @click="toggleSelectAssociation(association)"
                    >
                      <v-list-item-action>
                        <v-progress-circular
                          v-if="association.id === loadingGetAssociation"
                          indeterminate
                          color="primary"
                          :size="18"
                          :width="2"
                        />
                        <v-icon
                          v-else
                          :color="association.id === associationSelected ? 'indigo darken-4' : ''"
                        >
                          {{ association.id === associationSelected ? 'mdi-close-box' : 'mdi-checkbox-blank-outline' }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ association.abbreviation }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-if="data.index < 15 || announcementId > 0"
                      v-bind="data.attrs"
                      :input-value="data.name"
                      :close="announcementId <= 0"
                      label
                      small
                      @click="data.select"
                      @click:close="removeSelectedCity(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                    <span
                      v-if="data.index === 15 && announcementId <= 0"
                      class="grey--text caption"
                    >
                      (+{{ announcement.cities.length - 15 }} outras)
                    </span>
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="info"
          outlined
          :loading="loadingSave"
          :disabled="announcementId > 0"
          @click="save"
        >
          <v-icon
            v-text="'mdi-email-send'"
            left
          />
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>

    <dialog-select-cities-that-did-not-respond-to-a-survey
      v-model="dialogSelectCitiesThatDidNotRespondToASurvey"
      @survey-selected="cities => announcement.cities = cities"
    />
  </v-dialog>
</template>

<script>
  import associationsApi from '@/api/associations'
  import announcementsApi from '@/api/announcements'
  import citiesApi from '@/api/cities'
  import typesOfContactsApi from '@/api/types-of-contacts'

  import 'quill/dist/quill.snow.css'
  import { quillEditor } from 'vue-quill-editor'

  export default {

    components: {
      quillEditor,
      DialogSelectCitiesThatDidNotRespondToASurvey: () => import('@/components/dialog/announcements/DialogSelectCitiesThatDidNotRespondToASurvey'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      announcementId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        loadingGetAssociation: null,
        announcement: {
          cities: []
        },
        typesOfContacts: [],
        cities: [],
        associations: [],
        associationSelected: null,
        dialogSelectCitiesThatDidNotRespondToASurvey: false,
        editorOption: {
          placeholder: 'Digite aqui o texto do e-mail',
          modules: {
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['link'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ]
          }
        }
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      title () {
        return this.announcementId ? 'Visualizar' : 'Adicionar'
      },

      selectedAllCities () {
        return this.announcement.cities.length === this.cities.length
      },

      selectedSomeCities () {
        return this.announcement.cities.length > 0 && !this.selectedAllCities
      },

      icon () {
        if (this.selectedAllCities) return 'mdi-close-box'
        if (this.selectedSomeCities) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
    },

    mounted () {
      this.loadCities()
      this.loadTypesOfContacts()
      this.loadAssociations()
    },

    methods: {

      async load () {
        try {
          this.announcement = {
            subject: null,
            replyTo: null,
            text: null,
            cities: [],
            typesOfContacts: this.typesOfContacts.map((t) => t.id),
          }

          if (!this.announcementId) {
            return
          }

          this.loading = true

          const response = await announcementsApi.get(this.announcementId)

          this.announcement = response.data.announcement
          this.announcement.cities = this.announcement.cities.map((city) => city.cityId)
          this.announcement.typesOfContacts = this.announcement.typesOfContacts.map((type) => type.typeOfContactId)
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          await announcementsApi.insert(this.announcement)

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: 'Comunicado registrado com sucesso! As mensagens já estão sendo enviadas...',
            timeout: 7000
          })
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

      async loadCities () {
        try {
          this.loading = true

          const response = await citiesApi.list({
            limit: 1000,
            offset: 0,
          })

          this.cities = response.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadTypesOfContacts () {
        try {
          this.loading = true

          const response = await typesOfContactsApi.list({
            limit: 1000,
            offset: 0,
          })

          this.typesOfContacts = response.data.typesOfContacts
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadAssociations () {
        try {
          this.loading = true

          const response = await associationsApi.list({
            limit: 1000,
            offset: 0,
          })

          this.associations = response.data.associations
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      toggleSelectAll () {
        this.$nextTick(() => {
          if (this.selectedAllCities) {
            this.announcement.cities = []
          } else {
            this.announcement.cities = this.cities.map((c) => c.id)
          }
        })
      },

      async toggleSelectAssociation (association) {
        this.$nextTick(async () => {
          if (this.associationSelected === association.id) {
            this.announcement.cities = []
            this.associationSelected = null
            return
          }

          try {
            this.loadingGetAssociation = association.id

            const response = await associationsApi.get(association.id)

            this.announcement.cities = response.data.association.cities.map((c) => c.cityId)
            this.associationSelected = association.id
          } catch (e) {
            this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          } finally {
            this.loadingGetAssociation = null
          }
        })
      },

      removeSelectedCity (item) {
        const index = this.announcement.cities.indexOf(item.id)
        if (index >= 0) this.announcement.cities.splice(index, 1)
      },

    

    },

  }
</script>

